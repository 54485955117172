.cartaObservacion{
    width: auto;
    background-color: var(--background-slide);
    border-radius: 15px;
    padding: 10px;
    flex:1;
    margin-right: 200px;
    height: auto;
    display: flex;
    flex-direction: column;
}

.cartaObservacion:hover {
    box-shadow: 0 4px 8px rgb(0, 0, 0); /* Add shadow on hover */
}

.cartaObservacion h2{
    color: var(--accent-secondary);
    font-size: var(--font-size-h3);
    font-weight: 600;
}

.cartaObservacion h3{
    font-size: var(--font-size-h4);
    font-weight: 500;
    color: var(--text-secondary)
}
.cartaObservacion h5 {
    color: var(--accent-secondary);
    font-size: var(--font-size-h4);
    font-weight: 400;
    margin-left: 10px;
}

.infoEspecifica div {
    display: flex;
    align-items: center; 
}


.btnObservaciones {
    margin-left: 10px;
    background-color: var(--secondary);
    border: 1px solid transparent;
    border-radius: .5rem;
    box-sizing: border-box;
    color: #FFF;
    cursor: pointer;
    display: flex;
    font-family: "Inter var",ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 0.65rem;
    padding: .75rem 1.2rem;
    border-radius: 40px;
    text-align: center;
    text-decoration: none #6B7280 solid;
    text-decoration-thickness: auto;
    transition-duration: .2s;
    transition-property: background-color,border-color,color,fill,stroke;
    transition-timing-function: cubic-bezier(.4, 0, 0.2, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: auto;

}

.btnObservaciones:hover {
    background-color: var(--secondary-hover);
}

.btnObservaciones {
    align-items: right;
    margin-left: auto;
}