.principalStudentModInfo h2 {
    color: #fff;
    font-size: 4rem;
    margin-top: 0;
    font-family: "Roboto";
    text-align: center;
}

.principalStudentModInfo h4 {
    color: #fff;
    font-size: 25px;
    font-family: "Roboto";
    margin-top: 40px;
    margin-left: 160px;
}

.principalStudentModInfo .input-box {
    position: relative;
    width: 30%;
    height: 50px;
    margin: 40px 0;
    margin-top: 40px;
    margin-left: 160px;
}

.principalStudentModInfo button {
    margin-left: 160px;
    background-color: var(--secondary) ;
  border: 1px solid transparent;
  border-radius: .75rem;
  box-sizing: border-box;
  color: var(--text);
  cursor: pointer;
  flex: 0 0 auto;
  font-family: "Inter var",ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5rem;
  padding: 0.5rem 1rem;
  text-align: center;
  text-decoration: none #6B7280 solid;
  text-decoration-thickness: auto;
  transition-duration: .2s;
  transition-property: background-color,border-color,color,fill,stroke;
  transition-timing-function: cubic-bezier(.4, 0, 0.2, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  height: auto;
}

.principalStudentModInfo button:hover {
    background-color: var(--secondary-hover);
}