.listaEstudiantes{
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100px;
}

.listaEstudiantes h2 {
    margin-top: 0;
    font-family: "Poppins";
    color: var(--text);
    font-size: var(--font-size-h2);
}

.listaEstudiantes .dropdown {
    width: 13%;
}

.btnAgregarEstudiante{
    background-color: var(--secondary) ;
    border: 1px solid transparent;
    border-radius: .75rem;
    box-sizing: border-box;
    color: var(--text);
    cursor: pointer;
    flex: 0 0 auto;
    font-family: "Inter var",ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.5rem;
    padding: 0.5rem 1rem;
    text-align: center;
    text-decoration: none #6B7280 solid;
    text-decoration-thickness: auto;
    transition-duration: .2s;
    transition-property: background-color,border-color,color,fill,stroke;
    transition-timing-function: cubic-bezier(.4, 0, 0.2, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 30vh;
    height: auto;
  
    margin-bottom: 10px;
}

.btnAgregarEstudiante:hover{
    background-color: var(--secondary-hover);
}
