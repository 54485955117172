.cartaComentario{
    width: auto;
    background-color: var(--background-slide);
    border-radius: 15px;
    padding: 10px;
    margin-left: 10px;
    flex:1;
    margin-right: 200px;
    margin-bottom: 5px;
    height: auto;
    display: flex;
    flex-direction: column;
}

.cartaComentario:hover {
    box-shadow: 0 4px 8px rgb(0, 0, 0); /* Add shadow on hover */
}

.cartaComentario h2{
    color: var(--accent-secondary);
    font-size: var(--font-size-h3);
    font-weight: 600;
}

.box {
    margin-left: 5px;
}

.cartaComentario h3{
    font-size: var(--font-size-h4);
    font-weight: 500;
    color: var(--text-secondary)
}
.cartaComentario h5 {
    color: var(--accent-secondary);
    font-size: var(--font-size-h4);
    font-weight: 400;
    margin-left: 10px;
}

.infoEspecifica div {
    display: flex;
    align-items: center; 
}

.checkbox {
    position: right;
}
