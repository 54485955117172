 @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=VT323&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.wrapper {
    width:fit-content;
    background: var(--background-slide);
    color: var(--text);
    border-radius: 10px;
    padding: 30px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin:auto;
    margin-top: calc(50vh - 200px);
}

.wrapper h1 {
    font-size: 36px;
    text-align: center;
}

.wrapper .input-box {
    position: relative;
    width: 100%;
    height: 50px;
    margin: 30px 0;
}

.wrapper .forgot {
    display: flex;
    justify-content: space-between;
    font-size: 14.5px;
    margin: -15px 0 15px;
    margin-top: 2vh;
}

.forgot a {
    color: var(--primary-text);
    text-decoration: none;
}

.forgot a:hover {
    text-decoration: underline;
}

.input-box input {
    width: 100%;
    height: 100%;
    background: transparent;
    border: 2px solid rgba(255, 255, 255, .2);
    outline: none;
    border-radius: 40px;
    font-size: 16px;
    color: #fff;
    padding: 20px 45px 20px 20px;
    font-family: "Poppins";
}

.input-box input::placeholder {
    color: #fff;
}

.input-box .icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    color: #fff;
}

.wrapper button {
    width: 100%;
    height: 45px;
    background: var(--secondary);
    border: none;
    outline: none;
    border-radius: 40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    cursor: pointer;
    font-size: 16px;
    color: var(--secondary-text);
    font-weight: 700;
}

.login {
    width: auto;
    text-align: center;
    height: 45px;
    background: #fff;
    border: none;
    outline: none;
    border-radius: 40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    cursor: pointer;
    font-size: 18px;
    color: #333;
    font-weight: 700;
}


.dropdown-container {
    position: relative;
    display: inline-block;
    justify-content: center; 
    align-items: center; 
  }
  
  .dropdown-header {
    background-color: #fff;
    color: #333;
    padding: 10px;
    border: 1px solid #ccc;
    cursor: pointer;
    border-radius: 5px;
    min-width: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .dropdown-header:hover {
    background-color: #f0f0f0;
  }
  
  .arrow {
    margin-left: 10px;
  }
  
  .arrow.up {
    transform: rotate(180deg);
  }
  
  .dropdown-list {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1;
  }
  
  .dropdown-list li {
    padding: 10px;
    cursor: pointer;
    color: #333;
  }
  
  .dropdown-list li:hover {
    background-color: #f0f0f0;
  }
  .dropdown-top-text {
    color: #fff;
    font-size: 14px;
    margin-bottom: 5px; /* Add some space between the top text and the dropdown header */
  }

.wrapper .botonOlvidar {
    margin-bottom: 3px;
    margin-top: 5px;
}

.wrapper .botonOlvidar button:hover {
    background-color: #253d4e;
}