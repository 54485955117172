@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=VT323&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.Reset-password {
    width:fit-content;
    background: var(--background-slide);
    color: var(--text);
    border-radius: 10px;
    padding: 30px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin:auto;
    margin-top: calc(50vh - 200px);
}

.Reset-password h1 {
    font-size: 36px;
    text-align: center;
}

.Reset-password .input-box {
    position: relative;
    width: 100%;
    height: 50px;
    margin: 30px 0;
}

.input-box input {
    width: 100%;
    height: 100%;
    background: transparent;
    border: 2px solid rgba(255, 255, 255, .2);
    outline: none;
    border-radius: 40px;
    font-size: 16px;
    color: #fff;
    padding: 20px 45px 20px 20px;
    font-family: "Poppins";
}

.input-box input::placeholder {
    color: #fff;
}

.input-box .icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    color: #fff;
}

.botonActualizar button {
    width: 50%;
    height: 45px;
    background: var(--secondary);
    border: none;
    outline: none;
    border-radius: 40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    cursor: pointer;
    font-size: 16px;
    color: var(--secondary-text);
    font-weight: 700;
}

.botonActualizar {
    margin-bottom: 3px;
    margin-top: 5px;
}

.botonActualizar button:hover {
    background-color: #253d4e;
}