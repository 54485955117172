/* google font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=VT323&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
/* layout */

@tailwind base;
@tailwind components;
@tailwind utilities;

.App{
  width: 100%;
  height: 100%;
}

:root {
  --text: #dce7f2;
  --text-secondary: #ecf2f8;

  --background: #050c14;
  --background-accent: #2962a3;
  --background-sidebar: #0a1829;
  --background-slide: #5c95d6;

  --primary: #7fb4ea;
  --primary-text: #050C14;
  --primary-hover: #3a8cdf;

  --secondary: #0d559f;
  --secondary-hover: #08335e;
  --secondary-text: #e8eff6;

  --accent: #1e8dfd;
  --accent-secondary: #011932;

  /* Font sizes for headers */
  --font-size-h1: 2.25rem;  /* 36px when base size is 16px */
  --font-size-h2: 1.75rem;  /* 28px */
  --font-size-h3: 1.375rem;  /* 22px */
  --font-size-h4: 1.125rem;  /* 18px */
  --font-size-h5: 1rem;  /* 16px, same as body font size */
  --font-size-h6: 0.875rem;  /* 14px */

  /* Font size for navigational elements and buttons */
  --font-size-button: 1rem;  /* 16px for clear readability */
}

body {
  background: var(--background);
  margin: 0;
  font-family: "Poppins";
  width: 100%;
}


.home{
  display: flex;
  flex-direction: column;
  height: 100vw;
}

.horizontal-container {
  display: flex;
  flex-direction: row;
  align-items: top; /* Optional: Align items vertically */
  justify-content: center; /* Optional: Align items horizontally */
  height: 100%;
  width: 100%;
}

.contenedorListas{
  flex: 1;
  display: flex;
  flex-direction: column;
}

.contenedorListas h2{
  color: #FFFFFF;
  
}


