.listaProfesores{
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.listaProfesores h2 {
    margin-top: 0;
    font-family: "Poppins";
    color: var(--text);
    font-size: var(--font-size-h2);
  }
.listaProfesores h3{
    font-size: 30px;
    color: #1e8dfd;
}