@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

.principal h2 {
    color: #fff;
    font-size: 4rem;
    margin-top: 0;
    font-family: "Roboto";
    text-align: center;
}

.principal h4 {
    color: #fff;
    font-size: 18px;
    font-family: "Roboto";
    margin-left: 80px;
}

.principal .input-box {
    position: relative;
    width: 30%;
    height: 50px;
    margin: 40px 0;
    margin-top: 40px;
    margin-left: 80px;
}

.principal .input-box input {
    width: 100%;
    height: 100%;
    background: #63696b;
    border: 2px solid rgba(255, 255, 255, .2);
    outline: none;
    border-radius: 40px;
    font-size: 16px;
    color: #fff;
    padding: 20px 45px 20px 20px;
}

.principal button {
    margin-left: 80px;
    background-color: #63696b;
    border: 1px solid transparent;
    border-radius: .75rem;
    box-sizing: border-box;
    color: #FFF;
    cursor: pointer;
    display: flex;
    font-family: "Inter var",ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 0.65rem;
    padding: .75rem 1.2rem;
    border-radius: 40px;
    text-align: center;
    text-decoration: none #6B7280 solid;
    text-decoration-thickness: auto;
    transition-duration: .2s;
    transition-property: background-color,border-color,color,fill,stroke;
    transition-timing-function: cubic-bezier(.4, 0, 0.2, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 10%;
    height: 35px;
}

.principal button:hover {
    background-color: #9C9998;
  }