.cartaActividad{
    width: auto;
    background-color: var(--background-slide);
    border-radius: 15px;
    padding: 10px;
    flex:1;
    margin-right: 200px;
    height: auto;
}

.cartaActividad:hover {
    box-shadow: 0 4px 8px rgb(0, 0, 0); /* Add shadow on hover */
    cursor: pointer;
}

.cartaActividad h2{
    color: var(--accent-secondary);
    font-size: var(--font-size-h3);
    font-weight: 600;
}

.cartaActividad h3{
    font-size: var(--font-size-h4);
    font-weight: 500;
    color: var(--accent-secondary)
}

.cartaActividad h4{
    font-size: var(--font-size-h4);
    font-weight: 500;
    color: var(--text-secondary)
}

.cartaActividad h5 {
    color: var(--accent-secondary);
    font-size: var(--font-size-h4);
    font-weight: 400;
    margin-left: 10px;
}

.cartaActividad div {
    display: flex;
    padding: 5px;
}

.cartaActividad-Multiple {
    flex-direction: column;
    align-items: left; 
    border: 2px solid var(--background-accent);
}

.cartaActividad button {
    margin-right: 15px;
    margin-top: 20px;
}

.previewImagenes {
    display: flex;                
    flex-wrap: wrap;              
    justify-content: center;      
    gap: 10px;                    
    padding: 20px;                
}

.img-estado {
    width: auto;              
    height: auto;           
    max-width: 200px;      
    max-height: 150px;        
    border-radius: 8px;        
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
