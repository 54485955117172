.cartaEstudiante{
    width: auto;
    background-color: var(--background-slide);
    border-radius: 15px;
    padding: 10px;
    flex:1;
    margin-right: 200px;
    height: auto;
    display: flex;
    flex-direction: column;
}

.cartaEstudiante:hover {
    box-shadow: 0 4px 8px rgb(0, 0, 0); /* Add shadow on hover */
}

.cartaEstudiante h2{
    color: var(--accent-secondary);
    font-size: var(--font-size-h3);
    font-weight: 600;
}

.cartaEstudiante h3{
    font-size: var(--font-size-h4);
    font-weight: 500;
    color: var(--text-secondary)
}
.cartaEstudiante h5 {
    color: var(--accent-secondary);
    font-size: var(--font-size-h4);
    font-weight: 400;
    margin-left: 10px;
}

.infoEspecifica div {
    display: flex;
    align-items: center; 
}
